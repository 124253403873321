// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ 
                     "navMenue",
                     "navBtn",
                     "searchInput"
                   ]

////search
  

  // connect() {
    
  // }
  // initGoogle() {
  //   this.autocomplete = new google.maps.places.Autocomplete(this.searchInputTarget, {
  //     fields: ["address_components", "geometry"],
  //     types: ["address"],
  //   })
  //   this.autocomplete.addListener('place_changed')
  // }
  // placeSelected() {


  //   // this.latTarget.value = place.geometry.location.lat();
  //   // this.lngTarget.value = place.geometry.location.lng();
  // }

  connect() {
    this.initGoogle();
  }

  disconnect() {
    if (this.autocomplete) {
      google.maps.event.clearInstanceListeners(this.autocomplete);
      this.autocomplete = null;
    }
  }

  initGoogle() {
    if (typeof google !== 'undefined' && google.maps && google.maps.places) {
      this.autocomplete = new google.maps.places.Autocomplete(this.searchInputTarget, {
        fields: ["address_components", "geometry"],
        types: ["address"],
      });

      this.autocomplete.addListener('place_changed', this.handlePlaceChanged.bind(this));
    }
  }

  handlePlaceChanged() {
    // Handle place changed event here
  }

  ////search

  ////navbar

    display_nav() {

      const navMenueState = this.navMenueTarget.style.display

      if (navMenueState !== "block") {
        this.navMenueTarget.style.display = "block";
      } 
    }

    hide_nav() {

      const navMenueState = this.navMenueTarget.style.display

      if (navMenueState === "block") {
        this.navMenueTarget.style.display = "none";
      }
    }
}
window.initMap = () => {
  const event = new CustomEvent("map-loaded", {
    bubbles: true,
    cancelable: true
  });
  window.dispatchEvent(event);
};
