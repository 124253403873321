// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ 
                     "myOrganisationBtn",
                     "myBookingsBtn",
                     "myStudiosBtn",
                     "mySettingsBtn",
                     "organisationsView",
                     "bookingsView",
                     "studiosView",
                     "settingsView",
                     "myOrgForm",
                     "myOrgView",
                     "myStuForm",
                     "myStuView",
                     "mybookingView",
                     "myOrgProView",
                     "searchInput"
                   ]
  ////search
  

  // connect() {
    
  // }
  // initGoogle() {
  //   this.autocomplete = new google.maps.places.Autocomplete(this.searchInputTarget, {
  //     fields: ["address_components", "geometry"],
  //     types: ["address"],
  //   })
  //   this.autocomplete.addListener('place_changed')
  // }

  connect() {
    this.hideBookingsViewAfterDelay();
  }

  hideBookingsViewAfterDelay() {
    const bookingsViewTarget = document.querySelector('[data-layout-target="flash"]');

    if (bookingsViewTarget) {
      setTimeout(() => {
        bookingsViewTarget.classList.add('hidden');
      }, this.data.get('layoutDelay') || 4500);
    }
  }


  connect() {
    this.initGoogle();
  }

  disconnect() {
    if (this.autocomplete) {
      google.maps.event.clearInstanceListeners(this.autocomplete);
      this.autocomplete = null;
    }
  }

  initGoogle() {
    if (typeof google !== 'undefined' && google.maps && google.maps.places) {
      this.autocomplete = new google.maps.places.Autocomplete(this.searchInputTarget, {
        fields: ["address_components", "geometry"],
        types: ["address"],
        componentRestrictions: { country: "za"},
      });

      this.autocomplete.addListener('place_changed', this.handlePlaceChanged.bind(this));
    }
  }
  placeSelected() {


    // this.latTarget.value = place.geometry.location.lat();
    // this.lngTarget.value = place.geometry.location.lng();
  }

  ////search

  ////organisaions navbar

    display_bookings() {
      const bookingsViewTarget = document.querySelector('[data-organisation-target="bookingsView"]');
      const organisationsViewStateTarget = document.querySelector('[data-organisation-target="organisationsView"]');
      const studiosViewStateTarget = document.querySelector('[data-organisation-target="studiosView"]');
      const settingsViewStateTarget = document.querySelector('[data-organisation-target="settingsView"]');
      const organisationsViewState = this.organisationsViewTarget.style.display
      const bookingsViewState = this.bookingsViewTarget.style.display
      const studiosViewState = this.studiosViewTarget.style.display
      const settingsViewState = this.settingsViewTarget.style.display

      if (bookingsViewState !== "block") {
        bookingsViewTarget.style.display = "block";
      } 
      if(organisationsViewState !== "none") {
        organisationsViewStateTarget.style.display = "none";
      }
      if(studiosViewState !== "none") {
        studiosViewStateTarget.style.display = "none";
      }
      if(settingsViewState !== "none") {
        settingsViewStateTarget.style.display = "none";
      }
      window.scrollTo(0, 0);
    }
    display_organisation() {

      const bookingsViewTarget = document.querySelector('[data-organisation-target="bookingsView"]');
      const organisationsViewStateTarget = document.querySelector('[data-organisation-target="organisationsView"]');
      const studiosViewStateTarget = document.querySelector('[data-organisation-target="studiosView"]');
      const settingsViewStateTarget = document.querySelector('[data-organisation-target="settingsView"]');
      const organisationsViewState = this.organisationsViewTarget.style.display
      const bookingsViewState = this.bookingsViewTarget.style.display
      const studiosViewState = this.studiosViewTarget.style.display
      const settingsViewState = this.settingsViewTarget.style.display

      if (organisationsViewState !== "block") {
        organisationsViewStateTarget.style.display = "block";
      } 
      if(bookingsViewState !== "none") {
        bookingsViewTarget.style.display = "none";
      }
      if(studiosViewState !== "none") {
        studiosViewStateTarget.style.display = "none";
      }
      if(settingsViewState !== "none") {
        settingsViewStateTarget.style.display = "none";
      }
      window.scrollTo(0, 0);
    }
    display_studio() {

      const bookingsViewTarget = document.querySelector('[data-organisation-target="bookingsView"]');
      const organisationsViewStateTarget = document.querySelector('[data-organisation-target="organisationsView"]');
      const studiosViewStateTarget = document.querySelector('[data-organisation-target="studiosView"]');
      const settingsViewStateTarget = document.querySelector('[data-organisation-target="settingsView"]');
      const organisationsViewState = this.organisationsViewTarget.style.display
      const bookingsViewState = this.bookingsViewTarget.style.display
      const studiosViewState = this.studiosViewTarget.style.display
      const settingsViewState = this.settingsViewTarget.style.display

      if (studiosViewState !== "block") {
        studiosViewStateTarget.style.display = "block";
      } 
      if(bookingsViewState !== "none") {
        bookingsViewTarget.style.display = "none";
      }
      if(organisationsViewState !== "none") {
        organisationsViewStateTarget.style.display = "none";
      }
      if(settingsViewState !== "none") {
        settingsViewStateTarget.style.display = "none";
      }
      window.scrollTo(0, 0);
    }
    display_settings() {
      const bookingsViewTarget = document.querySelector('[data-organisation-target="bookingsView"]');
      const organisationsViewStateTarget = document.querySelector('[data-organisation-target="organisationsView"]');
      const studiosViewStateTarget = document.querySelector('[data-organisation-target="studiosView"]');
      const settingsViewStateTarget = document.querySelector('[data-organisation-target="settingsView"]');
      const organisationsViewState = this.organisationsViewTarget.style.display
      const bookingsViewState = this.bookingsViewTarget.style.display
      const studiosViewState = this.studiosViewTarget.style.display
      const settingsViewState = this.settingsViewTarget.style.display

      if (settingsViewState !== "block") {
        settingsViewStateTarget.style.display = "block";
      } 
      if(bookingsViewState === "block") {
        bookingsViewTarget.style.display = "none";
      }
      if(organisationsViewState !== "none") {
        organisationsViewStateTarget.style.display = "none";
      }
      if(studiosViewState === "block") {
        studiosViewStateTarget.style.display = "none";
      }
      window.scrollTo(0, 0);
    }

    display_org_form() {
      const myOrgFormTarget = document.querySelector('[data-organisaion-target="myOrgForm"]');
      myOrgFormTarget.style.display = "block"
    }
    hide_org_form() {
      const myOrgFormTarget = document.querySelector('[data-organisaion-target="myOrgForm"]');
      myOrgFormTarget.style.display = "none"
    }

    view_organisation() {
      const myOrgViewTaget = document.querySelector('[data-organisaion-target="myOrgView"]');
      myOrgViewTaget.style.display = "block"
    }

    hide_organisation() {
      const myOrgViewTaget = document.querySelector('[data-organisaion-target="myOrgView"]');
      myOrgViewTaget.style.display = "none"
    }

    display_studio_form() {
      const myStuFormTarget = document.querySelector('[data-organisaion-target="myStuForm"]');
      myStuFormTarget.style.display = "block"
    }
    hide_studio_form() {
      const myStuViewTarget = document.querySelector('[data-organisaion-target="myStuForm"]');
      myStuViewTarget.style.display = "none"
    }
    // Open the modal for viewing a specific studio's details
    view_org(event) {
      const studioCard = event.currentTarget.closest('.org__view-card-container');
      const studioModal = studioCard.querySelector('[data-organisation-target="myOrgView"]');
      
      if (studioModal) {
        studioModal.style.display = "block";
      } else {
        console.log("Studio modal not found.");
      }
    }
    hide_org() {
      const modalContainers = document.querySelectorAll('.org__modal-container');
      
      modalContainers.forEach(modalContainer => {
        if (modalContainer.style.display === "block") {
          modalContainer.style.display = "none";
        }
      });
    }
    view_booking(event) {
      const studioCard = event.currentTarget.closest('.book-card-containerr');
      const studioModal = studioCard.querySelector('[data-organisation-target="mybookingView"]');
      
      if (studioModal) {
        studioModal.style.display = "block";
      } else {
        console.log("Studio modal not found.");
      }
    }
    hide_booking() {
      const modalContainers = document.querySelectorAll('.org__modal-container');
      
      modalContainers.forEach(modalContainer => {
        if (modalContainer.style.display === "block") {
          modalContainer.style.display = "none";
        }
      });
    }
    view_studio(event) {
      const studioCard = event.currentTarget.closest('[data-organisaion-target="myStu"]');
      const studioModal = studioCard.querySelector('[data-organisaion-target="myStuView"]');
      
      if (studioModal) {
        studioModal.style.display = "block";
      } else {
        console.log("Studio modal not found.");
      }
    }
    hide_studio() {
      const modalContainers = document.querySelectorAll('.org__modal-container');
      
      modalContainers.forEach(modalContainer => {
        if (modalContainer.style.display === "block") {
          modalContainer.style.display = "none";
        }
      });
    }
    
    display_org_edit_form() {
      const myStuFormTarget = document.querySelector('[data-organisaion-target="myOrgProView"]');
      myStuFormTarget.style.display = "block"
    }
    hide_org_edit_form() {
      const myStuViewTarget = document.querySelector('[data-organisaion-target="myOrgProView"]');
      myStuViewTarget.style.display = "none"
    }
}
