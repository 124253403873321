// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ 
                     "myPdBtn",
                     "myBdBtn",
                     "myBBtn",
                     "myPdView",
                     "myBdView",
                     "myBView",
                     "formBookingOrder"
                   ]

  ////profile page togle personal-details/banking-details

  connect() {
    this.hideBookingsViewAfterDelay();
  }

  hideBookingsViewAfterDelay() {
    const bookingsViewTarget = document.querySelector('[data-layout-target="flash"]');

    if (bookingsViewTarget) {
      setTimeout(() => {
        bookingsViewTarget.classList.add('hidden');
      }, this.data.get('layoutDelay') || 4500);
    }
  }

  personal_view() {

    const myPdViewState = document.querySelector('[data-user-target="myPdView"]');


    if (myPdViewState !== "block") {
      this.myPdViewTarget.style.display = "block";
      this.myBdViewTarget.style.display = "none";
      this.myPdBtnTarget.classList.add("profile__nav-link-active");
      this.myBdBtnTarget.classList.remove("profile__nav-link-active");
      this.myBBtnTarget.classList.remove("profile__nav-link-active");
    } 
  }

  banking_view() {

    const myBdViewState = document.querySelector('[data-user-target="myBdView"]');


    if (myBdViewState !== "block") {
      this.myPdViewTarget.style.display = "none";
      this.myBdViewTarget.style.display = "block";
      this.myBdBtnTarget.classList.add("profile__nav-link-active");
      this.myPdBtnTarget.classList.remove("profile__nav-link-active");
      this.myBBtnTarget.classList.remove("profile__nav-link-active");
    } 
  }
  booking_view() {

    const myBViewState = document.querySelector('[data-user-target="myBView"]');


    if (myBViewState !== "block") {
      this.myPdViewTarget.style.display = "none";
      this.myPdViewTarget.style.display = "none";
      this.myBViewTarget.style.display = "block";
      this.myBBtnTarget.classList.add("profile__nav-link-active");
      this.myPdBtnTarget.classList.remove("profile__nav-link-active");
      this.myBdBtnTarget.classList.remove("profile__nav-link-active");
    } 
  }

  display_form() {
    const myOrgFormTarget = document.querySelector('[data-user-target="form"]');
    myOrgFormTarget.style.display = "block"
  }
  hide_form() {
    const myOrgFormTarget = document.querySelector('[data-user-target="form"]');
    myOrgFormTarget.style.display = "none"
  }

  // display_booking_order_form() {
  //   const myOrgFormTarget = document.querySelector('[data-user-target="formBookingOrder"]');
  //   myOrgFormTarget.style.display = "block"
  // }
  // hide_booking_order_form() {
  //   const myOrgFormTarget = document.querySelector('[data-user-target="formBookingOrder"]');
  //   myOrgFormTarget.style.display = "none"
  // }
  display_booking_order_form(event) {
    const studioCard = event.currentTarget.closest('.book__view-card-wrapper');
    const studioModal = studioCard.querySelector('[data-user-target="formBookingOrder"]');
    
    if (studioModal) {
      studioModal.style.display = "block";
    } else {
      console.log("Studio modal not found.");
    }
  }
  hide_booking_order_form() {
    const modalContainers = document.querySelectorAll('.org__modal-container');
    
    modalContainers.forEach(modalContainer => {
      if (modalContainer.style.display === "block") {
        modalContainer.style.display = "none";
      }
    });
  }
}
