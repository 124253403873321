// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "myBtn",
    "myModal"
  ]

  connect() {
    this.initializeModal();
  }

  initializeModal() {
    this.myBtnTarget.addEventListener("click", () => {
      this.myModalTarget.style.display = "block";
    });
  }

  search() {
    this.myModalTarget.style.display = "block";
    console.log("Studio modal not found.");

  }

  hide_search() {
    this.myModalTarget.style.display = "none";
  }
  connect() {
    this.hideBookingsViewAfterDelay();
  }

  hideBookingsViewAfterDelay() {
    const bookingsViewTarget = document.querySelector('[data-layout-target="flash"]');

    if (bookingsViewTarget) {
      setTimeout(() => {
        bookingsViewTarget.classList.add('hidden');
      }, this.data.get('layoutDelay') || 4500);
    }
  }
}
